import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
//import FamiliaChartArea from "../components/Familia/FamiliaChartArea";
import FamiliaMainHead from "../components/Familia/FamiliaMainHead";
import FamiliaRowBody from "../components/Familia/FamiliaRowBody";
//import { FamilyBody } from "../components/Familia/FamilyBody";
import Empujes from "../components/popups/Empujes";
import Massive from "../components/popups/Massive";
import Done from "../components/popups/Done";
import "../global/styles/familia.css";
import SelectionLogo from "../components/popups/SelectionLogo";
import LeftSeachBar from "../components/common/LeftSeachBar";
import { url } from "../pages/configuracion.jsx"

const CadenaSeleccionada = () => {

  //inicio consumo servicio web con hook's
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cadena_query = urlParams.get('cadena');
  var fecha_filtro = urlParams.get('fecha_filtro');
  if(!cadena_query){
    cadena_query = 'JUMBO';
  }
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  if(!fecha_filtro){
    fecha_filtro = today;
  }
  //Si el formulario de fecha es nulo o vacio queda today por defecto
  const [FamilyBody, setFamilyBody] = useState();
  const fetchApi = async () => {
    const response = await fetch(url+"getForecast", {
      method: 'POST',
      body: JSON.stringify({
          token: window.sessionStorage.getItem("token"),
          cadena: cadena_query, 
          fecha_inicio: fecha_filtro,
          limit: "4",
          agrupacion: "cadena_familia",
          filtro: null
          })
      });

      console.log(response.status);
      const responseJSON = await response.json();
      setFamilyBody(responseJSON);

  };

  useEffect(() => {
    fetchApi()
  }, []);
  //fin consumo servicio web

  const [popupState, setPopupState] = useState(null);
  const [ShowLogos, setShowLogo] = useState(null);

  useEffect(() => {
    setShowLogo(() => {
      return window.sessionStorage.getItem("redirection_search");
    });

    setTimeout(() => {
      window.sessionStorage.removeItem("redirection_search");
    }, 5000);
  }, []);

  return (
    <div className="Familia">
      <LeftSeachBar />
      {popupState == "first" && <Empujes setPopupState={setPopupState} />}
      {popupState == "second" && <Massive setPopupState={setPopupState} />}
      {popupState == "third" && <Done setPopupState={setPopupState} />}

      {ShowLogos == "yes" && <SelectionLogo />}

      <Header dropdown={true} />
      {
        //console.log(!FamilyBody ? 'Cargando...': FamilyBody)
      }
      {/* chart area */}
      { /*
      !FamilyBody ? 'Cargando Gráfico...' : <FamiliaChartArea ObjFamilyBody={FamilyBody}/>
      */}
      {
        //<FamiliaChartArea />
      } 
      <div className="table_area">
        <FamiliaMainHead />
        { !FamilyBody ? 'Cargando...' : FamilyBody.map((EachRow) => (
          <FamiliaRowBody
            setPopupState={setPopupState}
            topheading={EachRow.topheading}
            firstCol={EachRow.firstCol}
            secondCol={EachRow.secondCol}
            thirdCol={EachRow.thirdCol}
            fourthCol={EachRow.fourthCol}
            fifthCol={EachRow.fifthCol}
            tipo_filtro = "cadena_familia"
            familia = {FamilyBody}
          />
        ))
        }
      </div>
    </div>
  );
};
export default CadenaSeleccionada;
