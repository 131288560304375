import React from "react";
import tick from "../../assets/img/DC_saved.svg";
const Done = ({ setPopupState }) => {
  return (
    <div className="popup_wrapper">
      <div className="popup EmpujesMassive done">
        <span className="close_icon" onClick={(e) => setPopupState(null)}>
          <i class="fa-solid fa-xmark"></i>
        </span>
        <div className="body_area">
          <img src={tick} alt="" />
          <p>El empuje ha sido ingresado exitosamente</p>
        </div>
      </div>
    </div>
  );
};
export default Done;
