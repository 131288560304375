import React, { useState } from "react";
import { Link } from "react-router-dom";
import Boton from "../../assets/img/Boton.svg";
import { useNavigate } from "react-router-dom";
const SubcategoryRow = ({
  topheading,
  firstCol,
  secondCol,
  thirdCol,
  fourthCol,
  fifthCol,
  setPopupState,
}) => {
  let Navigate = useNavigate();
  const [color, setColor] = useState(false);
  const [active, setactive] = useState(false);
  return (
    <div className="FamiliaRowBody">
      <div className="head">
        <div className="left_side">
          <h1>{topheading.value}</h1>
        </div>

        <div className="bolon_wrapper sub_category_wrapper">
          <div
            className="bolan_catergory_wrapper"
            onClick={(e) => {
              Navigate(topheading.link);
            }}
            onMouseEnter={(e) => {
              setColor(true);
            }}
            onMouseLeave={(e) => {
              setColor(false);
            }}
          >
            {color ? (
              <img src={topheading.colorimg} alt="" />
            ) : (
              <img src={topheading.img} alt="" />
            )}

            <p>{topheading.right_para}</p>
          </div>
          <img src={Boton} alt="" onClick={(e) => setactive(!active)} />
          <ul
            className={active && "active"}
            onClick={(e) => {
              setPopupState("first");
              localStorage.setItem('filtro_popup', topheading.value);
              localStorage.setItem('filtro_categoria', "cadena_material");
            }}
          >
            <li>Ver Empuje</li>
          </ul>
        </div>
      </div>

      <div className="body">
        <div className="box">
          <p className="first_td first_td_img">
            {
              //<img src={firstCol.value} alt="" />
              firstCol.value
            }
          </p>
        </div>
        <div className="box">
          <div style={{ color: "#000" }}>
            <p>{secondCol}</p>
          </div>
        </div>
        <div className="long_box box">
          <p>{thirdCol[0]}</p>
          <p>{thirdCol[1]}</p>
          <p>{thirdCol[2]}</p>
          <p>{thirdCol[3]}</p>
          <div className="button_wrapper">
            <p className="button blue">{thirdCol[4]}</p>
            <p className="button yellow">{thirdCol[5]}</p>
          </div>
        </div>
        <div className="box">
          <p>{fourthCol[0]}</p>
          <p>{fourthCol[1]}</p>
          <p>{fourthCol[2]}</p>
          <p>{fourthCol[3]}</p>

          <button className="yellow">{fourthCol[4].value}</button>
        </div>
        <div className=" box">
          <button
            className="red"
            id={fifthCol.type == "button_outline" && "outline"}
          >
            <img src={fifthCol.img} alt="" />

            {fifthCol.value}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubcategoryRow;
