import React from "react";
import arrow_head from "../../assets/img/arrow_head.png";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
var fecha_filtro = urlParams.get('fecha_filtro');
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();
today = dd + '.' + mm + '.' + yyyy;
if(!fecha_filtro){
  fecha_filtro = today;
}else{
  var fecha_filtro_array = fecha_filtro.split("-");
  fecha_filtro = fecha_filtro_array[2]+"."+fecha_filtro_array[1]+"."+fecha_filtro_array[0];
}

var fecha_filtro_array = fecha_filtro.split(".");
var fecha_funcional = fecha_filtro_array[2]+"-"+fecha_filtro_array[1]+"-"+fecha_filtro_array[0];

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

var semana_sellout = [];
var semana_forecast = [];
for(var i = 0; i <= 28; i += 7){
  var s = new Date(fecha_funcional);
  var f = new Date(fecha_funcional);
  //console.log(s+"  ffesfsf "+fecha_funcional);
  s.setDate(s.getDate() + 1 - i);
  f.setDate(f.getDate() + 1 + i);
  var s_n_str = getWeekNumber(s);
  var f_n_str = getWeekNumber(f);
  semana_sellout.push(s_n_str);//actual, actual - 1, actual -2, actual - 3
  semana_forecast.push(f_n_str);//actual
}
console.log(semana_forecast);
const FamiliaMainHead = () => {
  return (
    <div className="FamiliaMainHead">
      <div className="head_box" style={{ display: "flex" }}>
        <p>Alertas</p>
        <img src={arrow_head} alt="" style={{ marginLeft: 10 }} />
      </div>
      <div className="head_box">
        <select name="" id="" disabled>
          <option value="Cadena">Item</option>
          <option value="Familia">Familia</option>
          <option value="SubFamilia">Sub Familia</option>
          <option value="SKU">SKU</option>
        </select>
      </div>
      <div className="head_box">
        <div className="top">
          <p>
            Sell Out <span className="date" id="fecha_filtro_s">{fecha_filtro}</span>
          </p>
        </div>
        <div className="bottom">
          <p id="sn1">S.{semana_sellout[4][1]}</p>
          <p id="sn2">S.{semana_sellout[3][1]}</p>
          <p id="sn3">S.{semana_sellout[2][1]}</p>
          <p id="sn4">S.{semana_sellout[1][1]}</p>
          <div className="button_wrapper">
            <p className="button blue">S.O. Esperado</p>
            <p className="button yellow">S.O. Actual</p>
          </div>
        </div>
      </div>

      <div className="head_box">
        <div className="top">
          <p>
            Forecast <span className="date" id="fecha_filtro_f">{fecha_filtro}</span>
          </p>
        </div>
        <div className="bottom">
          <p id="fn1">F {semana_forecast[0][1]}</p>
          <p id="fn2">F {semana_forecast[1][1]}</p>
          <p id="fn3">F {semana_forecast[2][1]}</p>
          <p id="fn4">F {semana_forecast[3][1]}</p>
          <p style={{ display: "flex", alignItems: "center" }}>
            Accuracy
            <img src={arrow_head} alt="" style={{ marginLeft: 10 }} />
          </p>
        </div>
      </div>
      <div className="head_box" style={{ display: "flex" }}>
        <p>Empujes</p>
        <img src={arrow_head} alt="" style={{ marginLeft: 10 }} />
      </div>
    </div>
  );
};
export default FamiliaMainHead;
