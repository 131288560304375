import "./App.css";
import ProductSelection from "./pages/ProductSelection";
import DemandConnectHome from "./pages/DemandConnectHome";
import Login from "./pages/Login";
import CadenaSeleccionada from "./pages/CadenaSeleccionada";
import Familia from "./pages/Familia";
import Dashboard from "./pages/Dashboard";
import Dashboard_dfa from "./pages/Dashboard_dfa";
import Dashboard_sap from "./pages/Dashboard_sap";
import Dashboard_forecast from "./pages/Dashboard_forecast";

import "./global/styles/common.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SubCategory from "./pages/SubCategory";
import Productosconalerta1 from "./pages/Productosconalerta1";
//importar componente de servicio web


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/SubCategory" element={<SubCategory />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Dashboard_dfa" element={<Dashboard_dfa />} />
          <Route path="/Dashboard_sap" element={<Dashboard_sap />} />
          <Route path="/Dashboard_forecast" element={<Dashboard_forecast />} />
          <Route
            path="/Productosconalerta1"
            element={<Productosconalerta1 />}
          />

          <Route path="/CadenaSeleccionada" element={<CadenaSeleccionada />} />
          <Route path="/Familia" element={<Familia />} />
          <Route path="/DemandConnectHome" element={<DemandConnectHome />} />
          <Route path="/ProductSelection" element={<ProductSelection />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
