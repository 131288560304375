//export const url = "http://localhost:8081/promociones-web-app/api/";
export const url = "https://optimusprice.pricemaker.io/api/";
export const url_local = "https://demandconnect.pricemaker.io/";
//export const url_local = "http://localhost:3000/";
export const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
);

if(window.location.href != url_local){
  if (!window.sessionStorage.getItem("token") || window.sessionStorage.getItem("token") == ""){
    window.location.replace("/");
    //alert("NO LOGIN");
  }
}

/*
(async () => {
    const rawResponse = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({})
    });
    const content = await rawResponse.json();
    console.log(content);
    console.log("autorizado, sesion activa");
  })().catch(function(error){
    console.log(error);
    console.log("NO AUTORIZADO, SIN SESION");
    //window.location.replace('/');
  });
*/