// buttons img

import buttonlast1 from "../../assets/img/Empujes/DC__Empuje_exhibicion.svg";
import buttonlast2 from "../../assets/img/Empujes/DC__Empuje_inventario.svg";
import buttonlast3 from "../../assets/img/Empujes/DC__Empuje_riesgo.svg";
import img9 from "../../assets/img/Family Icons/DC__Yoghurt_gray.svg";
import arrowup from "../../assets/img/DC_arrow up.svg";
import arrowdown from "../../assets/img/DC_arrow down.svg";
import img1 from "../../assets/img/Family Icons/DC__crema_gray.svg";

import img2 from "../../assets/img/Family Icons/DC__jugos_gray.svg";
import img2c from "../../assets/img/Family Icons/DC__crema_green copia.svg";
import img3 from "../../assets/img/Family Icons/DC__Leche en polvo_gray.svg";
import img3c from "../../assets/img/Family Icons/DC__Leche en polvo_green.svg";

import img4 from "../../assets/img/Family Icons/DC__Leche liquida_gray.svg";
import img4c from "../../assets/img/Family Icons/DC__Leche liquida_green.svg";

import img5 from "../../assets/img/Family Icons/DC__Manjar_gray.svg";
import img5c from "../../assets/img/Family Icons/DC__Manjar_green.svg";

export const Productosconalerta1List = [
  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Crema",
      img: img1,
    },
    firstCol: {
      fill: true,
      value: arrowup,
    },
    secondCol: "635113001",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "75%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "1200",
      img: buttonlast1,
    },
  },

  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Jugos",

      img: img2,
    },
    firstCol: {
      fill: false,
      value: arrowup,
    },
    secondCol: "635113002",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "25",
      img: buttonlast2,
    },
  },
  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",

      right_para: "Leche en Polvo",
      img: img3,
    },
    firstCol: {
      fill: true,
      value: arrowup,
    },
    secondCol: "635113041",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "105%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "1200",
      img: buttonlast3,
    },
  },

  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowup,
    },
    secondCol: "635113005",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "99%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "100",
      img: buttonlast3,
    },
  },

  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowup,
    },
    secondCol: "635113006",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "75%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "1200",
      img: buttonlast1,
    },
  },
  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowdown,
    },
    secondCol: "635113009",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "25",
      img: buttonlast2,
    },
  },
  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowdown,
    },
    secondCol: "635113051",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button_outline",
      value: "0",
    },
  },

  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowdown,
    },
    secondCol: "635123001",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "25",
      img: buttonlast1,
    },
  },
  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowdown,
    },
    secondCol: "635113801",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "25",
      img: buttonlast2,
    },
  },

  {
    topheading: {
      link: "/Familia",
      value: "Lorem ipsum dolor sit amet.",
      right_para: "Yoghurt | Batido 125",
      img: img9,
    },
    firstCol: {
      fill: true,
      value: arrowdown,
    },
    secondCol: "635113010",
    thirdCol: ["39.532", "47.357", "50.904", "52.304", "59.304", "50.304"],
    fourthCol: [
      "50.532",
      "51.357",
      "56.904",
      "49.304",
      {
        type: "button",
        value: "15%",
      },
    ],

    fifthCol: {
      type: "button",
      value: "25",
      img: buttonlast1,
    },
  },
];
