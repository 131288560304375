import React, { useState } from "react";
import search_left from "../../assets/img/search_left.svg";

import img1c from "../../assets/img/Family Icons/DC__crema_green.svg";

import img2c from "../../assets/img/Family Icons/DC__crema_green copia.svg";

import img3c from "../../assets/img/Family Icons/DC__Leche en polvo_green.svg";

import img4c from "../../assets/img/Family Icons/DC__Leche liquida_green.svg";

import img5c from "../../assets/img/Family Icons/DC__Manjar_green.svg";

import img6c from "../../assets/img/Family Icons/DC__Mantequilla_green.svg";

import img7c from "../../assets/img/Family Icons/DC__Margarina_green.svg";

import img8c from "../../assets/img/Family Icons/DC__Postres_green.svg";

import img9c from "../../assets/img/Family Icons/DC__Mantequilla_green copia.svg";

import img10c from "../../assets/img/Family Icons/DC__Queso fresco_green.svg";
import img11c from "../../assets/img/Family Icons/DC__Queso maduro_green.svg";

import img_circle from "../../assets/img/DC_select off.svg";
import DC_search_gray from "../../assets/img/DC_search_gray.svg";
import date from "../../assets/img/date.png";
import { Link } from "react-router-dom";
import { DocumentScannerOutlined } from "@mui/icons-material";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const cadena_query = urlParams.get('cadena');
const fecha_filtro = urlParams.get('fecha_filtro');
const str_url = "/Familia?cadena="+cadena_query+"&filtro=";
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();
today = yyyy + '-' + mm + '-' + dd;


const LeftSeachBar = () => {
  function buscarFecha(){
    var fecha_busqueda = document.getElementById("fecha_filtro").value;
    var url_string = window.location.href;
    url_string = url_string.split("&fecha_filtro")[0]+"&fecha_filtro="+fecha_busqueda;
    window.location.replace(url_string);
  }

  const [show, setShow] = useState(false);
  const [fecha_filtro_, setFecha_filtro_] = useState("");

  const handleChange = (e) => {
    e.preventDefault(); // prevent the default action
    setFecha_filtro_(e.target.value); // set name to e.target.value (event)
  };

  return (
    <div className="leftSearchbarWrapper" id={show && "active"}>
      <img
        src={search_left}
        alt=""
        className="leftSearchbarIcon"
        onClick={(e) => {
          setShow(!show);
        }}
      />
      <div className="leftSearchbar">
        <i class="fa-solid fa-close" onClick={(e) => {
          setShow(!show);
        }}></i>
        {/*
        <div className="search_input_wrapper">
        
          <img src={DC_search_gray} alt="" />
          <input type="text" placeholder="" disabled/>
      
          </div>
        */}
        <div className="date_wrapper">
          <button onClick={() => buscarFecha()}>Buscar</button>
          <img src={date} alt="" />
          <input type="date" name="fecha_filtro" id="fecha_filtro" />
          {
            //
            //!fecha_filtro ? document.getElementById("fecha_filtro").value = today: document.getElementById("fecha_filtro").value= fecha_filtro 
          }
          <i class="fa-solid fa-chevron-down"></i>
          
        </div>
        
        <p className="selection_wrapper">
          <img src={img_circle} alt="" />
          <Link to={'/CadenaSeleccionada?cadena='+cadena_query} onClick={() => window.location.reload()}>Seleccionar Todas</Link>
        </p>
        <ul>
          <li>
            <img src={img1c} alt="" />
            <Link to={str_url+'Crema'}><p>Crema</p></Link>
            {
              //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img2c} alt="" />
            <p>Jugos</p>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img3c} alt="" />
            <Link to={str_url+'Leche en Polvo'}><p>Leche en Polvo</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img4c} alt="" />
            <Link to={str_url+'Leche Líquida'}><p>Leche Liquida</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img5c} alt="" />
            <Link to={str_url+'Manjar'}><p>Manjar</p></Link>
            {
              //</Link><i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img6c} alt="" />
            <Link to={str_url+'Mantequilla'}><p>Mantequilla</p></Link>
            {
              //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img7c} alt="" />
            <Link to={str_url+'Margarina'}><p>Margarinas</p></Link>
            {
              //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img8c} alt="" />
            <Link to={str_url+'Postres'}><p>Postres</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img10c} alt="" />
            <Link to={str_url+'Queso Fresco'}><p>Queso Fresco</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img11c} alt="" />
            <Link to={str_url+'Queso Maduro'}><p>Queso Maduro</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
          <li>
            <img src={img9c} alt="" />
            <Link to={str_url+'Yoghurt'}><p>Yoghurt</p></Link>
            {
            //<i class="fa-solid fa-angle-right"></i>
            }
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LeftSeachBar;
