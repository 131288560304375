import React, { useState } from "react";
import "../global/styles/login.css";
import logo from "../assets/img/auth/Soprole_logo.png";
import Login_user_gray from "../assets/img/auth/Login_user_gray.svg";
import Login_user_color from "../assets/img/auth/Login_user_color.svg";
import Login_contraseña_gray from "../assets/img/auth/Login_contrasena_gray.svg";
import Login_contraseña_color from "../assets/img/auth/Login_contrasena_color.svg";
import logo_bottom from "../assets/img/auth/demand connect y promo booster_pricemaker_negro.png";
import { Link } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import { WindowSharp } from "@mui/icons-material";
import { url } from "../pages/configuracion.jsx"


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const page_type_params = urlParams.get('email')
console.log("email::::: -->> "+page_type_params);
if(page_type_params){
  window.location.replace("/");
}

//document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

function loginValid(){
    var email_ = document.getElementById('email').value;
    var pw_ = document.getElementById('pw').value;
    (async () => {
      const rawResponse = await fetch(url+"login", {
        method: 'POST',
        body: JSON.stringify({email: email_, pw: pw_})
      });
      const content = await rawResponse.json();
    
      console.log(content);
      window.sessionStorage.setItem("token", content.token);
      document.getElementById("aviso").innerHTML = "Bienvenid@ "+content.usuario+", estamos redireccionando...";
      document.cookie = "auth=yes";
      document.cookie = "username="+content.usuario;
      window.location.replace('/DemandConnectHome');

    })().catch(function(error){
      console.log(error);
      console.log("error credenciales");
      document.getElementById("aviso").innerHTML = "Error en credenciales";
    });
}

const Login = () => {
  window.sessionStorage.removeItem("token");
  const [user, setuser] = useState(Login_user_gray);
  const [lock, setlock] = useState(Login_contraseña_gray);
  return (
    <div className="Login">
      <div className="form_area">
        <form action="">
          <div className="header_wrapper">
            <img src={logo} alt="" />
          </div>
          <div className="body_wrapper">
            <p>
              Bienvenid@ a <span>PRICEMAKER</span>
            </p>
            <div className="input_wrapper">
              <img src={user} alt="" />
              <input
                type="text" name="email" id="email"
                placeholder="correo@ejemplo.com"
                onChange={(e) => {
                  if (e.target.value == "") {
                    setuser(Login_user_gray);
                  } else {
                    setuser(Login_user_color);
                  }
                }}
              />
            </div>{" "}
            <div className="input_wrapper">
              <img src={lock} alt="" />
              <input
                type="password" name="pw" id="pw"
                placeholder="Contraseña"
                onChange={(e) => {
                  if (e.target.value == "") {
                    setlock(Login_contraseña_gray);
                  } else {
                    setlock(Login_contraseña_color);
                  }
                }}
              />
            </div>
            <div className="button_wrapper">
              <Link to="/">
                <button onClick={loginValid}>Ingresar</button>
              </Link>
            </div>
            <div className="link_wrapper" id="aviso">
              {/*<a href="#">Olvidé mi Contraseña</a>*/}
            </div>
          </div>
        </form>

        <div className="logo_wrapper">
          <img src={logo_bottom} alt="" />
        </div>
      </div>
    </div>
  );
};
export default Login;
