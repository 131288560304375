import React, { useEffect, useState }  from "react";
import { EmpujesRow } from "./EmpujesRow";
//import { EmpujesRowBody } from "./EmpujesRowBody";
import { url } from "../../pages/configuracion.jsx"
import date from "../../assets/img/date.png";
import DC__Delete_gray from "../../assets/img/DC__Delete_gray.svg";

const Empujes = ({ setPopupState }) => {

  //inicio consumo servicio web con hook's
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cadena_query = urlParams.get('cadena');
  var fecha_filtro = urlParams.get('fecha_filtro');
  if(!cadena_query){
    cadena_query = 'JUMBO';
  }
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  if(!fecha_filtro){
    fecha_filtro = today;
  }
  //Si el formulario de fecha es nulo o vacio queda today por defecto
  const [EmpujeBody, setEmpujeBody] = useState();
  const fetchApi = async () => {
    const response = await fetch(url+"getEmpuje", {
      method: 'POST',
      body: JSON.stringify({
        token: window.sessionStorage.getItem("token"),
          cadena: cadena_query, 
          fecha_inicio: fecha_filtro,
          limit: "100",
          agrupacion: localStorage.filtro_categoria,
          filtro: localStorage.filtro_popup
          })
      });

      console.log(response.status);
      const responseJSON = await response.json();
      setEmpujeBody(responseJSON);

  };

  useEffect(() => {
    fetchApi()
  }, []);
  //fin consumo servicio web
  const deleteRow = (id) => {
    console.log(id);
    let response = window.confirm("Estás segur@ que deseas eliminar este empuje?");
    if(response){
      alert("eliminado");
      document.getElementById(id).remove();
    }else{
      return response;
    }
  }

  const addRow = () => {
   let theChild = document.getElementById("add_row"); 
   let theChildClone = theChild.cloneNode(true);
   const id__ = 'id' + (new Date()).getTime();
   theChildClone.id = id__;
   /*
   let html_delete = (
    <div className="img_wrapper">
      <img src alt="" 
      onClick={(e) => {
        deleteRow(theChildClone.id)
      }}
      />
    </div>
   )
   ;
   */
   let html_delete = "<div className='img_wrapper'><a href='#'>x</a></div>";
   //theChildClone.appendChild(html_delete);
   theChildClone.innerHTML = theChildClone.innerHTML + html_delete;
   let theParent = document.getElementById("section_empujes");
   theParent.insertBefore(theChildClone, theParent.firstChild);
  }
  return (
    <div className="popup_wrapper">
      <div className="popup Empujes">
        <span
          className="close_icon"
          onClick={(e) => {
            setPopupState(null);
          }}
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
        <div className="section Empujessection1">
          
          {
          //INICIO <h1>Empujes</h1>
          }
{/*}
<div className="empujes_row" id="add_row">
      <div className="input_wrapper">
        <label htmlFor="">SKU</label>
        <input type="text" id="sku_empuje"/>
          
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Grupo Empresa</label>
        <select id="grupo_empresa_empuje">
          <option value="4025">4025</option>
          <option value="101">101</option>
          <option value="365">365</option>
        </select>
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Material</label>
        <select id="material_empuje">
          <option value="Seleccionar">Seleccionar</option>
          <option value="002450-001">002450-001</option>
          <option value="0004536-01">0004536-01</option>
          <option value="002548-003">002548-003</option>
        </select>
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Kilos</label>
        <input type="number" id="kilos_empuje"/>
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Motivo</label>
        <select id="motivo_empuje">
          <option value="Seleccionar">Seleccionar</option>
          <option value="002450-001">002450-001</option>
          <option value="0004536-01">0004536-01</option>
          <option value="002548-003">002548-003</option>
        </select>
      </div>

      <div className="input_wrapper">
        <label htmlFor="">Fecha Despacho</label>
        <div className="date_wrapper">
          <img src={date} alt="" />
          <input type="date" name="" id="fecha_empuje" />
          <i class="fa-solid fa-chevron-down"></i>
        </div>
      </div>

      
    </div>

          {
          //FIN
          //<EmpujesRow />
          }
          <div className="Empujes_top_link_wrapper">
            <a href="#" 
              onClick={(e) => {
                addRow()
              }}
            >+ Ingresar empuje</a>
          </div>
        */}
        </div>
            

        <div className="section Empujessection2" id="section_empujes">
          <h1>Historial de Empujes </h1>
          {
            //<EmpujesRowBody />
          }
    { !EmpujeBody ? 'Cargando...' : EmpujeBody.map((EachRow) => 
         {
          return (
            <div className="empujes_row" id={"empujes_row_"+EachRow.sku+"_"+EachRow.grupo_empresa+"_"+EachRow.material+"_"+EachRow.fecha_despacho}>
              <div className="input_wrapper">
                <select id="empuje_row">
                  <option value={EachRow.sku}>{EachRow.sku}</option>
                </select>
              </div>
              <div className="input_wrapper">
                <select>
                  <option value={EachRow.grupo_empresa}>{EachRow.grupo_empresa}</option>
                </select>
              </div>
              <div className="input_wrapper">
                <select>
                  <option value={EachRow.material}>{EachRow.material}</option>
                </select>
              </div>
              <div className="input_wrapper">
                <input type="number" value={EachRow.kilos}/>
              </div>
              <div className="input_wrapper">
                <select>
                  <option value={EachRow.motivo} selected>{EachRow.motivo}</option>
                </select>
              </div>
        
              <div className="input_wrapper">
                <div className="date_wrapper">
                  <img src={date} alt="" />
                  <input type="date" name="" id="" value={EachRow.fecha_despacho}/>
                  <i class="fa-solid fa-chevron-down"></i>
                </div>
              </div>
        
              <div className="img_wrapper">
                <img src={DC__Delete_gray} alt="" 
                onClick={(e) => {
                  deleteRow("empujes_row_"+EachRow.sku+"_"+EachRow.grupo_empresa+"_"+EachRow.material+"_"+EachRow.fecha_despacho)
                }}
                />
              </div>
            </div>
          );
         }
        )
        }
      
          
        </div>
        <div className="Empujes_bottom_link_wrapper">
            
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPopupState("second");//second
              }}
            >
              Carga Masiva
            </a>
            <a
              href="#"
              className="fill"
              onClick={(e) => {
                e.preventDefault();
                setPopupState(null);
              }}
            >
              Cerrar
            </a>
          </div>
      </div>
    </div>
  );
};
export default Empujes;
