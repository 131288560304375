import React, { useState, useEffect } from "react";
import Header from "../components/common/Header";
import InformationSection from "../components/home/InformationSection";
import ChainSelection from "../components/home/ChainSelection";
import LastNew from "../components/home/LastNew";
import "../global/styles/home.css";
import useScript from "./useScript.js"
import {url} from "./configuracion.jsx"
import TableauReport from 'tableau-react';

const DemandConnectHome = () => {
  useScript('https://tableauserver-azure.pricemaker.io/javascripts/api/viz_v1.js');

  //Si el formulario de fecha es nulo o vacio queda today por defecto
  const [FamilyExtendBody, setFamilyExtendBody] = useState();
  const fetchApi = async () => {
    const response = await fetch(url+"getChart", {
      method: 'POST',
      body: JSON.stringify({
        token: window.sessionStorage.getItem("token"),
          tabs_permission : 2, 
          dtarget_site: "Soprole",
          project: "Revision_Promociones_DC",
          page: "PromocionesSMU"
          })
      });

      console.log(response.status);
      const responseJSON = await response.json();
      setFamilyExtendBody(responseJSON);

  };

  useEffect(() => {
    fetchApi()
  }, []);
  //fin consumo servicio web

  return (
    
    <div className="DemandConnectHome">
      <Header />

      <div className="body_area">
  {
    //Tableau script
  }  
     
     { !FamilyExtendBody ? 'Cargando Dashboard Promociones 1...' : 
      <TableauReport
      url="https://tableauserver-azure.pricemaker.io/t/Soprole/views/Revision_Promociones_DC/PromocionesSMU"
      token={FamilyExtendBody.token_server}
      />
     }


  {
    //FIN Tableau script
  }   
      </div>
    </div>
  );
};
export default DemandConnectHome;
