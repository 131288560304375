import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import FamiliaChartArea from "../components/Familia/FamiliaChartArea";
import FamiliaMainHead from "../components/Familia/FamiliaMainHead";
import SubcategoryRow from "../components/Familia/SubcategoryRow";
import { SubCategoryList } from "../components/Familia/SubCategory";
import Empujes from "../components/popups/Empujes";
import Massive from "../components/popups/Massive";
import Done from "../components/popups/Done";
import "../global/styles/familia.css";
import SelectionLogo from "../components/popups/SelectionLogo";
import { Productosconalerta1List } from "../components/Familia/Productosconalerta1";
import LeftSeachBar from "../components/common/LeftSeachBar";
const Productosconalerta1 = () => {
  const [popupState, setPopupState] = useState(null);
  const [ShowLogos, setShowLogo] = useState(null);

  useEffect(() => {
    setShowLogo(() => {
      return window.sessionStorage.getItem("redirection_search");
    });

    setTimeout(() => {
      window.sessionStorage.removeItem("redirection_search");
    }, 5000);
  }, []);
  return (
    <div className="Familia">
      <LeftSeachBar />
      {popupState == "first" && <Empujes setPopupState={setPopupState} />}
      {popupState == "second" && <Massive setPopupState={setPopupState} />}
      {popupState == "third" && <Done setPopupState={setPopupState} />}

      {ShowLogos == "yes" && <SelectionLogo />}

      <Header dropdown={true} />

      {/* chart area */}
      <FamiliaChartArea />

      <div className="table_area">
        <FamiliaMainHead />
        {Productosconalerta1List.map((EachRow) => (
          <SubcategoryRow
            setPopupState={setPopupState}
            topheading={EachRow.topheading}
            firstCol={EachRow.firstCol}
            secondCol={EachRow.secondCol}
            thirdCol={EachRow.thirdCol}
            fourthCol={EachRow.fourthCol}
            fifthCol={EachRow.fifthCol}
          />
        ))}
      </div>
    </div>
  );
};
export default Productosconalerta1;
