import React from "react";
import logo1 from "../../assets/img/Supermarket logos/layer1.png";
import logo2 from "../../assets/img/Supermarket logos/Jumbo.png";
import logo3 from "../../assets/img/Supermarket logos/mayorista.png";
import logo4 from "../../assets/img/Supermarket logos/Santa isabel-01.png";
import logo5 from "../../assets/img/Supermarket logos/tottus-logo.png";
import logo6 from "../../assets/img/Supermarket logos/unimarc.png";
import { Link } from "react-router-dom";
const SelectionLogo = () => {
  return (
    <div className="popup_wrapper">
      <div className="popup SelectionLogoPopUp">
        <h1>Seleciona una cadena</h1>
        <ul className="logo_wrapper">
          <li>
            <Link to="/">
              <img src={logo1} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={logo2} alt="" />{" "}
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={logo3} alt="" />{" "}
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={logo4} alt="" />{" "}
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={logo5} alt="" />{" "}
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={logo6} alt="" />{" "}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default SelectionLogo;
