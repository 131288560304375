import React, { useState } from "react";
//import FimiliaChart from "./FimiliaChart";
import dropdown_button from "../../assets/img/dropdown_button.svg";
import ReactApexChart from "react-apexcharts";

const FamiliaChartArea = ({ObjFamilyBody}) => {
  //Separacion de OBJ JSON recibido
  console.log(ObjFamilyBody);
  let sellin_1 = 0;
  let sellin_2 = 0;
  let sellin_3 = 0;
  let sellin_4 = 0;
  let forecast_1 = 0;
  let forecast_2 = 0;
  let forecast_3 = 0;
  let forecast_4 = 0;
  let forecast_5 = 0;
  let forecast_6 = 0;
  let forecast_7 = 0;
  let forecast_8 = 0;
  let forecast_9 = 0;
  let forecast_10 = 0;
  let forecast_11 = 0;
  let forecast_12 = 0;
  let forecast_past_1 = 0;
  let forecast_past_2 = 0;
  let forecast_past_3 = 0;
  let forecast_past_4 = 0;
  
  ObjFamilyBody.map((EachRow) => {
      //console.log("HEADING::: "+EachRow.secondCol)
      //(console.log(EachRow.thirdCol[0]))
      sellin_1 += parseInt(EachRow.seventhCol[0])
      sellin_2 += parseInt(EachRow.seventhCol[1])
      sellin_3 += parseInt(EachRow.seventhCol[2])
      sellin_4 += parseInt(EachRow.seventhCol[3])
      forecast_1 += parseInt(EachRow.fourthCol[0].replace(",",""))
      forecast_2 += parseInt(EachRow.fourthCol[1].replace(",",""))
      forecast_3 += parseInt(EachRow.fourthCol[2].replace(",",""))
      forecast_4 += parseInt(EachRow.fourthCol[3].replace(",",""))
      forecast_5 = parseInt(EachRow.forecastComplete[4])
      forecast_6 = parseInt(EachRow.forecastComplete[5])
      forecast_7 = parseInt(EachRow.forecastComplete[6])
      forecast_8 = parseInt(EachRow.forecastComplete[7])
      forecast_9 = parseInt(EachRow.forecastComplete[8])
      forecast_10 = parseInt(EachRow.forecastComplete[9])
      forecast_11 = parseInt(EachRow.forecastComplete[10])
      forecast_12 = parseInt(EachRow.forecastComplete[11])
      forecast_past_1 += parseInt(EachRow.sixthCol[0])
      forecast_past_2 += parseInt(EachRow.sixthCol[1])
      forecast_past_3 += parseInt(EachRow.sixthCol[2])
      forecast_past_4 += parseInt(EachRow.sixthCol[3])
    }
  );
  console.log(sellin_1);
  //console.log(sellin_2);
  //Fin Datos

  //Fecha semanas::
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var fecha_filtro = urlParams.get('fecha_filtro');
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + '.' + mm + '.' + yyyy;
  if(!fecha_filtro){
    fecha_filtro = today;
  }else{
    var fecha_filtro_array = fecha_filtro.split("-");
    fecha_filtro = fecha_filtro_array[2]+"."+fecha_filtro_array[1]+"."+fecha_filtro_array[0];
  }

  var fecha_filtro_array = fecha_filtro.split(".");
  var fecha_funcional = fecha_filtro_array[2]+"-"+fecha_filtro_array[1]+"-"+fecha_filtro_array[0];

  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  var semana_sellout = [];
  var semana_forecast = [];
  for(var i = 0; i <= 28; i += 7){
    var s = new Date(fecha_funcional);
    var f = new Date(fecha_funcional);
    //console.log(s+"  ffesfsf "+fecha_funcional);
    s.setDate(s.getDate() + 1 - i);
    f.setDate(f.getDate() + 1 + i);
    var s_n_str = getWeekNumber(s);
    var f_n_str = getWeekNumber(f);
    semana_sellout.push(s_n_str);//actual, actual - 1, actual -2, actual - 3
    semana_forecast.push(f_n_str);//actual
  }
  console.log(semana_forecast);
  //Fin fecha semanas:::
  //INICIO CONFIGURACION GRAFICOS
  const FimiliaChart = ({ right = null, yTitle }) => {
    let Series = [
      {
        name: "Venta Real",
        data: [sellin_1, sellin_2, sellin_3, sellin_4],
      },
      {
        name: "Forecast",
        data: [forecast_past_1, forecast_past_2, forecast_past_3, forecast_past_4],
      },
    ];
  
    let Options = {
      chart: {
        type: "area",
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2B5A84", "#BCE3D9"],
      stroke: {
        width: 3,
      },
  
      yaxis: {
        tickAmount: 3,
        opposite: right ? true : false,
        labels: {
          show: true,
          style: {
            fontSize: "20px",
            colors: "#A39F9F",
          },
        },
        title: {
          text: yTitle,
          style: {
            fontSize: 16,
            color: "#A39F9F",
          },
        },
        axisBorder: {
          show: true,
          color: "#A39F9F",
          offsetX: 0,
          offsetY: 0,
        },
      },
      xaxis: {
        categories: [semana_sellout[4][1], semana_sellout[3][1], semana_sellout[2][1], semana_sellout[1][1]],
        labels: {
          show: true,
          style: {
            fontSize: "20px",
            colors: "#A39F9F",
          },
        },
      },
    };
  
    return (
      <div className="graph_state">
        <div className="chart_area">
          <ReactApexChart
            options={Options}
            series={Series}
            type="line"
            width="100%"
            height={300}
          />
        </div>
      </div>
    );
  };

  const ForecastChart = ({ right = null, yTitle }) => {
    let Series = [
      {
        name: "Forecast",
        data: [forecast_1, forecast_2, forecast_3, forecast_4, forecast_5, forecast_6, forecast_7, forecast_8, forecast_9, forecast_10, forecast_11, forecast_12],
      },
    ];
  
    let Options = {
      chart: {
        type: "area",
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2B5A84", "#BCE3D9"],
      stroke: {
        width: 3,
      },
  
      yaxis: {
        tickAmount: 3,
        opposite: right ? true : false,
        labels: {
          style: {
            fontSize: "20px",
            colors: "#A39F9F",
          },
        },
        title: {
          text: yTitle,
          style: {
            fontSize: 16,
            color: "#A39F9F",
          },
        },
        axisBorder: {
          show: true,
          color: "#A39F9F",
          offsetX: 0,
          offsetY: 0,
        },
      },
      xaxis: {
        categories: [semana_forecast[0][1],semana_forecast[1][1],semana_forecast[2][1]
        ,semana_forecast[3][1],semana_forecast[3][1]+1,semana_forecast[3][1]+2
        ,semana_forecast[3][1]+3,semana_forecast[3][1]+4,semana_forecast[3][1]+5,semana_forecast[3][1]+6,
        semana_forecast[3][1]+7,semana_forecast[3][1]+8],
        labels: {
          style: {
            fontSize: "20px",
            colors: "#A39F9F",
          },
        },
      },
    };
  
    return (
      <div className="graph_state">
        <div className="chart_area">
          <ReactApexChart
            options={Options}
            series={Series}
            type="line"
            width="100%"
            height={300}
          />
        </div>
      </div>
    );
  };
  //FIN CONFIGURACION GRAFICOS
  const [hide, sethide] = useState(true);
  return (
    <div className="FamiliaChartAreaWrapper" id={hide && "hide"}>
      <img src={dropdown_button} alt="" onClick={(e) => sethide(!hide)}/>
      {
       // onClick={(e) => sethide(!hide)}
      }
      
      <div className="FamiliaChartArea">
        <h1>Gráfico Comparativo - Totales</h1>
        <div className="bottom_area">
          <div className="chart_area">
            <div className="pure_left_chart">
              <p>Forecast KG. v/s Venta Real KG.</p>
              <FimiliaChart yTitle="Estimado v/s Real" />
            </div>
            <div className="pure_left_chart">
              <p>Forecast 12 semanas</p>
              
              <ForecastChart right yTitle="Forecast" />
              
            </div>
          </div>
        </div>
      </div>
      {/*}
      <div className="select_container">
        <div className="select_wrapper">
          <label htmlFor="">Famlilia 1</label>
          <select name="" id="">
            <option value="Mantequilla">Mantequilla</option>
          </select>
        </div>
        <div className="select_wrapper">
          <label htmlFor="">Sub Famlilia</label>
          <select name="" id="">
            <option value="(Valores Multiple)">(Valores Multiple)</option>
          </select>
        </div>
        <div className="select_wrapper">
          <label htmlFor="">Cadena 1</label>
          <select name="" id="">
            <option value="JUMBO">JUMBO</option>
          </select>
        </div>
        <div className="select_wrapper">
          <label htmlFor="">Material 1</label>
          <select name="" id="">
            <option value="(Todo)">(Todo)</option>
          </select>
        </div>
      </div>
      */}
    </div>
  );
};
export default FamiliaChartArea;
