import React from "react";
import "../global/styles/ProductSelection.css";
import Card from "../components/ProductSelection/Card";
import logo_one from "../assets/img/demand connect y promo booster_pricemaker_negro.png";
import logo_two from "../assets/img/demand connect y promo booster_promobooster_negro.png";
import logo_three from "../assets/img/demand connect y promo booster_demandconnect_negro.png";
const ProductSelection = () => {
  return (
    <div className="ProductSelection">
      <div className="cards_wrapper">
        <Card
          img={logo_one}
          text="Maecenas ligula erat, finibus bibendum
          commodo et, hendrerit ultrices dolor. Mauris metus arcu, mattis non quam sed, tristique rhoncus lacus. Donec tincidunt
          ligula in ornare vulputate."
          clickedbutton={true}
          buttontext="Proximamente"
        />
        <Card
          img={logo_two}
          text="Aliquam tincidunt varius luctus. Praesent
          sed nisi dapibus, blandit nulla non, pharetra augue. Nulla facilisi. Phasellus
          ornare in nunc vel euismod.."
          buttontext="Ingresar"
        />
        <Card
          img={logo_three}
          text="Fusce iaculis risus sed massa iaculis, pretium scelerisque neque aliquet. Cras luctus, massa vitae semper tincidunt, ligula orci semper massa, id ultrices
          ipsum erat ut lorem."
          buttontext="Ingresar"
        />

        <div className="long_card">
          <div className="presentation">
            <h1>¿Necesitas ayuda?</h1>
            <p>
              Ingresa a nuestro sistema de Soporte de Tickets y te contactaremos
              a la brevedad.
            </p>
          </div>
          <button>Necesito Ayuda</button>
        </div>
      </div>
    </div>
  );
};
export default ProductSelection;
