import React from "react";
import Soprole_logo from "../../assets/img/Soprole_logo.png";
import alert from "../../assets/img/DC_Home_Productos con alertas.svg";
import shop from "../../assets/img/DC_Home_Resumen de cadenas.svg";
import refresh from "../../assets/img/DC_Home_Resumen de cadenas copia.svg";
import InformationCard from "./InformationCard";
const InformationSection = () => {
  return (
    <div className="InformationSection">
      {/* logo area */}
      <img src={Soprole_logo} alt="" />

      <div className="cards_wrapper">
        { /*
          //<InformationCard img={alert} text="Ver Productos con Alertas" />
        }
        <InformationCard img={shop} text="Resumen de Cadenas" />
        <InformationCard img={refresh} text="Forecast Históricos" />
        */
        }  
      </div>
    </div>
  );
};
export default InformationSection;
