import React from "react";

const Massive = ({ setPopupState }) => {
  return (
    <div className="popup_wrapper">
      <div className="popup EmpujesMassive">
        <span className="close_icon" onClick={(e) => setPopupState("first")}>
          <i class="fa-solid fa-xmark"></i>
        </span>
        <h1>Carga Masiva de Empujes</h1>
        <div className="list_wrapper">
          <h1>Recuerda lo siguiente: </h1>
          <ol>
            <li>El formato del archivo debe ser xlsx, xls o csv.</li>
            <li>Los valores permitidos en motivo son.
            </li>
            <ul>
              <li>Alto Riesgo</li>
              <li>Exhibiciones</li>
              <li>Nivelación de Inventario</li>
              <li>Quiebre de Competencia</li>
            </ul>
            <li>
              La actualización y validación de los archivos demora 1 hr aproximadamente.
            </li>
            <li>Las actualizaciones se verán reflejadas en la plataforma</li>
          </ol>

          <div className="bottom_wrapper">
            

            <div className="link_wrapper_massive">
              <a href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://optimusprice.pricemaker.io','winname','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350')
              }}
              >Abrir gestor de archivos</a>
            </div>
          </div>

          <div className="button_wrapper">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setPopupState("first");
              }}
            >
              Volver atrás
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Massive;
