import React from "react";
import logo1 from "../../assets/img/Supermarket logos/Jumbo.png";
import layer1 from "../../assets/img/Supermarket logos/layer1.png";
import mayorista from "../../assets/img/Supermarket logos/mayorista.png";
import Santaisabel01 from "../../assets/img/Supermarket logos/Santa isabel-01.png";
import tottuslogo from "../../assets/img/Supermarket logos/tottus-logo.png";
import unimarc from "../../assets/img/Supermarket logos/unimarc.png";
import { Link } from "react-router-dom";
import { getCookieValue } from "../../pages/configuracion.jsx"

const ChainSelection = () => {
  var usuario = getCookieValue("username");
  return (
    <div className="ChainSelection">
      <h1>Hola {usuario}, selecciona una cadena</h1>
      <div className="logos_wrapper">
        <div className="logo_img">
        <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=JUMBO"}}>            <img src={logo1} alt="" />
          </a>
        </div>
        <div className="logo_img">
          {" "}
          <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=LIDER"}}>
            <img src={layer1} alt="" />{" "}
          </a>
        </div>
        <div className="logo_img">
          {" "}
          <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=MAYORISTA 10"}}>
            <img src={mayorista} alt="" />{" "}
          </a>
        </div>
        <div className="logo_img">
          {" "}
          <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=SANTA ISABEL"}}>
            <img src={Santaisabel01} alt="" />{" "}
          </a>
        </div>
        <div className="logo_img">
          {" "}
          <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=TOTTUS"}}>
            <img src={tottuslogo} alt="" />{" "}
          </a>
        </div>
        <div className="logo_img">
          {" "}
          <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=UNIMARC"}}>
            <img src={unimarc} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default ChainSelection;
