import React from "react";
import Header from "../components/common/Header";
import InformationSection from "../components/home/InformationSection";
import ChainSelection from "../components/home/ChainSelection";
import LastNew from "../components/home/LastNew";
import "../global/styles/home.css";

const DemandConnectHome = () => {
  return (
    <div className="DemandConnectHome">
      <Header />

      <div className="body_area">
        <InformationSection />
        <ChainSelection />
        {
          //<LastNew />
        }
      </div>
    </div>
  );
};
export default DemandConnectHome;
