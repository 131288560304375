import React from "react";

const Card = ({ img, text, buttontext, clickedbutton }) => {
  return (
    <div className="CardProduction">
      <div className="head_wrapper">
        <img src={img} alt="" />
      </div>
      <div className="body_wrapper">
        <p>{text}</p>
      </div>

      <div className="footer" id={`${clickedbutton && "clicked"}`}>
        <button>{buttontext}</button>
      </div>
    </div>
  );
};
export default Card;
