import React, { useState } from "react";
import Menu from "../../assets/img/DC_Home_Menu_01 2.svg";
import header_logo from "../../assets/img/demand connect y promo booster_demandconnect_negro.png";
import bulb from "../../assets/img/DC_Home_lightbulb.svg";
import img1 from "../../assets/img/01.png";
import img2 from "../../assets/img/DC_Home_enviar ticket.svg";
import img3 from "../../assets/img/DC_Home_ver ticket.svg";
import img4 from "../../assets/img/DC_Home_salir.svg";

import logo1 from "../../assets/img/Supermarket logos/layer1.png";
import logo2 from "../../assets/img/Supermarket logos/Jumbo.png";
import logo3 from "../../assets/img/Supermarket logos/mayorista.png";
import logo4 from "../../assets/img/Supermarket logos/Santa isabel-01.png";
import logo5 from "../../assets/img/Supermarket logos/tottus-logo.png";
import logo6 from "../../assets/img/Supermarket logos/unimarc.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getCookieValue } from "../../pages/configuracion.jsx"
import { Link } from "react-router-dom";


var url_string = window.location.href;
var url = new URL(url_string);
var cadena_url = url.searchParams.get("cadena");
var imagen_url_cadena_seleccionada = "";

switch(cadena_url){
  case "LIDER":
      imagen_url_cadena_seleccionada = {logo1}.logo1;
      break;
  case "JUMBO":
      imagen_url_cadena_seleccionada = {logo2}.logo2;
      break;
  case "MAYORISTA 10":
      imagen_url_cadena_seleccionada = {logo3}.logo3;
      break;
  case "SANTA ISABEL":
      imagen_url_cadena_seleccionada = {logo4}.logo4;
      break;
  case "TOTTUS":
      imagen_url_cadena_seleccionada = {logo5}.logo5;
      break;
  case "UNIMARC":
      imagen_url_cadena_seleccionada = {logo6}.logo6;
      break;
  default:
      imagen_url_cadena_seleccionada = {logo1}.logo1;
      break;
}

const Header = ({ dropdown }) => {
  const [dropdownFirst, setdropdownFirst] = useState(false);
  const [dropdownFirstInner, setdropdownFirstInner] = useState(false);
  const [bulbDropdown, setdbulbDropdown] = useState(false);
  const [profiledropdown, setprofiledropdown] = useState(false);
  const [HeaderDropdownCenteractive, setHeaderDropdownCenteractive] =
    useState(false);
  var usuario = getCookieValue("username");
  return (
    <header className="dashbaord_header">
      <img
        src={Menu}
        alt=""
        className="menu_img"
        onClick={(e) => setdropdownFirst(!dropdownFirst)}
      />

      <ul className="dropdown dropdown_first" id={dropdownFirst && "active"}>
        <li><Link to="/DemandConnectHome">Inicio</Link></li>
        <li><Link to="/Dashboard">Reportería Promociones</Link></li>
        <li><Link to="/Dashboard_dfa">Reportería Históricos</Link></li>
        <li><Link to="/Dashboard_sap">Reportería SAP</Link></li>
        <li><Link to="/Dashboard_forecast">Reportería Forecast v/s Venta Real</Link></li>
        
       { 
      /*
       <li><Link to="/CadenaSeleccionada" onClick={() => window.location.reload()}>Resumen de Cadenas</Link></li>
        <li>Ver Productos con Alertas</li>
        <li>Data SAP IBP</li>
        <li>Forecast Históricos</li>
        <li>Reportes</li>
        */}
        </ul>

      <div className="right_wrapper">
        <img src={header_logo} alt="" className="logo_img" />

        {dropdown && (
          <div className="middle_area">
            <p>Selecctionar Cadena</p>
            <div className="dropdown">
              <div className="head">
                <img src={imagen_url_cadena_seleccionada} alt="" id="imagen_principal_menu"/>
                <KeyboardArrowDownIcon
                  onClick={(e) =>
                    setHeaderDropdownCenteractive(!HeaderDropdownCenteractive)
                  }
                />
              </div>
              <ul className={HeaderDropdownCenteractive && "active"}>
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=LIDER"}}><img src={logo1} alt="" /></a>
                </li>
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=JUMBO"}}><img src={logo2} alt="" /></a>
                </li>
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=MAYORISTA 10"}}><img src={logo3} alt="" /></a>
                </li>
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=SANTA ISABEL"}}><img src={logo4} alt="" /></a>
                </li>
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=TOTTUS"}}><img src={logo5} alt="" /></a>
                </li>{" "}
                <li>
                  <a onClick={() => {window.location.href="/CadenaSeleccionada?cadena=UNIMARC"}}><img src={logo6} alt="" /></a>
                </li>
              </ul>
            </div>

            <div className="buttons_wrapper">
              <button onClick={() => {window.open("https://optimusprice.pricemaker.io/api/downloadxlsx/output_forecast.xlsx?tk="+window.sessionStorage.getItem("token"),"_blank")}}>Exportar Forecasts</button>
              <button onClick={() => {window.open("https://optimusprice.pricemaker.io/api/downloadxlsx/output_empujes.xlsx?tk="+window.sessionStorage.getItem("token"),"_blank")}}>Exportar Empujes</button>
            </div>
          </div>
        )}
        <nav>
          <li>
            <img
              src={bulb}
              alt=""
              className="bulb_img"
              onClick={(e) => setdbulbDropdown(!bulbDropdown)}
            />

            <ul className="dropdown_bulb" id={bulbDropdown && "active"}>
              <div className="head">
                <p>Noticias</p>
                <span className="badge">0 Noticias</span>
              </div>
              <div className="body_area">
              {/*
                <div className="box">
                  <img src={img1} alt="" />
                  <p>Te presentamos el nuevo sistema de Soporte de Tickets</p>
                </div>
                <div className="box">
                  <img src={img1} alt="" />
                  <p>Te presentamos el nuevo sistema de Soporte de Tickets</p>
                </div>
                */}
                <div className="link_wrapper">
                  <a href="#">Ver todas las Noticias</a>
                </div>
              </div>
            </ul>
          </li>

          <li className="profile_img_wrapper">
            <img
              className="profile_img"
              src="https://img1.freepng.es/20180331/ffe/kisspng-computer-icons-user-clip-art-user-5abf13dad7aed4.5909364715224718988835.jpg"
              alt=""
            />
            <p>{usuario}</p>
            <KeyboardArrowDownIcon
              onClick={(e) => setprofiledropdown(!profiledropdown)}
            />

            <ul className="profile_dropdown" id={profiledropdown && "active"}>
              <li>
                <img src={img2} alt="" />
                <a href="https://support.zdatalab.com/index.php?a=add" target="_blank">Enviar Ticket</a>
              </li>
              <li>
                <img src={img3} alt="" />
                <a href="https://support.zdatalab.com/ticket.php" target="_blank">Ver Ticket</a>
              </li>
              <li>
                <img src={img4} alt="" />
                <Link to="/">Salir</Link>
              </li>
            </ul>
          </li>
        </nav>
      </div>
    </header>
  );
};
export default Header;
